import { Stack, Box, Container, Heading, Center, Text, Divider, Icon, useMediaQuery } from '@chakra-ui/react'
import { GoLocation } from 'react-icons/go'
import { FiPhoneCall, FiTwitter } from 'react-icons/fi'
import { HiOutlineMail } from 'react-icons/hi'
import { FaFacebook } from 'react-icons/fa'

export default function ContactUs() {
    let [isMobile] = useMediaQuery("(max-width:600px)");

    return (
        <>
            <Container minW="full"
                backgroundColor="red" color="white">
                <br />
                <Heading color="black">Contact Us</Heading>
                <br /><br />
                <Center>
                    <Box maxWidth="3xl">
                        <Text fontSize="x-large">
                            The party is founded on the means through which
                            the people express themselves and are given a voice
                            to run the government they want.
                        </Text>
                    </Box>
                </Center>
                <br /><br />
                <Divider minW="100%" />
                <br />
                <Center>
                    <Stack direction={isMobile ? "column" : "row"} spacing="4">
                        <ContactCard icon={GoLocation}  title="Location" text="245 Mandera Road,Kileleshwa" more="P.O BOX 40016-00100 Nairobi" />
                        <ContactCard icon={FiPhoneCall} title="Phone" text="123 456 678" />
                        <ContactCard icon={HiOutlineMail} title="Email" text="upapartyke@gmail.com" />
                    </Stack>
                </Center>
                <br />
                <Center>
                    <Heading>Follow us on social media</Heading>
                    <Stack direction="row" spacing="4">
                        <FaFacebook fontSize="48" onClick={e=>{document.location.href="https://twitter.com/upakenya"}} 
                            color="#3b5998" href="https://twitter.com/upakenya"/>
                        <FiTwitter fontSize="48" 
                            onClick={e=>{document.location.href="https://twitter.com/upakenya"}}
                        color="#00acee" href="https://twitter.com/upakenya"/>

                    </Stack>
                </Center>

                <br /><br></br>
            </Container>
        </>
    )


}

let ContactCard = ({ icon, title, text, more=null }) => {

    return <>
        <Box w="56" borderRadius="lg" backgroundColor="white" color="black">
            <br />
            <br />
            <Center>
                <Icon borderRadius="xl" color="green" focusable="auto" as={icon} boxSize="4em" />
            </Center>
            <br />
            <Heading textAlign="center" fontSize="2xl">{title}</Heading>
            <Text textAlign="center">{text}</Text>
            {more && <Text p="2" textAlign="center">{more}</Text>}
            <br />

        </Box>

    </>


}