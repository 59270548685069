import {
    Container, Stack, Center, Heading, Divider, Image, Box, useMediaQuery
} from '@chakra-ui/react'
import { useState } from 'react'

export default function Gallery() {

    let [gallery,] = useState(['1', '2', '3', '4', '5', '6'])
    let [isMobile] = useMediaQuery("(max-width:600px)");
    

    // console.log(images)

    return (
        <>
            <Container maxW="full"
                backgroundColor="red" color="white">
                <br />
                <Heading color="black">Gallery</Heading>
                <br />
                <Divider minW="100%" />
            </Container>
            <br />  <br />

            <Container maxW="full">
                <Center>
                    <Stack direction={isMobile ? "column" : "row"} spacing="4">
                        {gallery.slice(0, 3).map((item) => {
                            return <GalleryItem src={`/gallery/${item}.jpeg`} />
                        })}
                    </Stack>
                </Center>
                <br /> <br />
                <Center>
                    <Stack direction={isMobile ? "column" : "row"} spacing="4">
                        {gallery.slice(3, 6).map((item) => {
                            return <GalleryItem src={`/gallery/${item}.jpeg`} />
                        })}
                    </Stack>
                </Center>
            </Container>


            <br />




        </>
    )


}

let GalleryItem = ({ type = "image", src }) => {

    return <>
        <Box>
            <Image src={src} height="64" w={['sm', 'sm', '3md', '3md']} borderRadius="lg" />
            <br />
        </Box>

    </>
}