import {
    Stack, Box, Divider, Icon, Spacer, Button, Link, useMediaQuery, useDisclosure,
    Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter,
    Image
} from '@chakra-ui/react'
import { CgProfile } from 'react-icons/cg'
import { useRef } from 'react'

export default function Header() {

    // let [isLargeScreen] = useMediaQuery("(min-width:600px)");
    let [isMobile] = useMediaQuery("(max-width:600px)");
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()

    return (
        <>
            <Stack direction="row" backgroundColor="white" maxW="full">
                <Box >
                    <Link href="/">
                        {/* <Heading color="white" fontSize="2xl" p="2">{data.partyName}</Heading> */}
                        <Image src="/logo.jpeg" maxH="60px" />
                    </Link>
                </Box>
                <Spacer />
                <Box p="2">
                    {(!isMobile) ? <Stack direction="row" p="2">
                        <Link href="/register"><Button variant="solid" colorScheme="green">Register</Button></Link>
                        {/* <Link href="/our-team"><Button variant="solid" colorScheme="green">Our Team</Button></Link>
                        <Link href="/gallery"><Button variant="solid" colorScheme="green">Gallery</Button></Link>
                        <Link href="/downloads"><Button variant="solid" colorScheme="green">Downloads</Button></Link> */}
                        {/* <Link href="/volunteer"><Button variant="solid" colorScheme="green">Volunteer</Button></Link> */}
                        {/* <Link href="/news"><Button variant="solid" colorScheme="green">News</Button></Link> */}
                        {/* <Link href="/about-us"><Button variant="solid" colorScheme="green">About Us</Button></Link>
                        
                        <Link href="/contact-us"><Button variant="solid" colorScheme="green">Contact Us</Button></Link> */}
                        <Link href="https://www.upakenya.com"><Button variant="solid" colorScheme="green">Go back to upakenya.com</Button></Link> 
                        <Link href="/login">
                            <Icon as={CgProfile} color="green" boxSize="8" />
                        </Link>
                    </Stack> :
                        <>
                            <Button ref={btnRef} colorScheme="green" onClick={onOpen}>
                                Menu
                            </Button>
                            <Drawer
                                isOpen={isOpen}
                                placement="right"
                                onClose={onClose}
                                finalFocusRef={btnRef}
                            >
                                <DrawerOverlay />
                                <DrawerContent>
                                    <DrawerCloseButton  />
                                    <DrawerHeader colorScheme="green">Menu</DrawerHeader>

                                    <DrawerBody>
                                        <Stack direction="column">
                                            <Link href="/"><Button w="full" variant="solid" colorScheme="green">Home</Button></Link>
                                            <Link href="/register"><Button w="full" variant="solid" colorScheme="green">Register</Button></Link>
                                            {/* <Link href="/volunteer"><Button w="full" variant="solid" colorScheme="green">Volunteer</Button></Link> */}
                                            {/* <Link href="/our-team"><Button w="full" variant="solid" colorScheme="green">Our Team</Button></Link>
                                            <Link href="/downloads"><Button  w="full" variant="solid" colorScheme="green">Downloads</Button></Link>
                                            <Link href="/contact-us"><Button w="full" variant="solid" colorScheme="green">Contact Us</Button></Link>
                                            <Link href="/gallery"><Button w="full" variant="solid" colorScheme="green">Gallery</Button></Link>
                                            <Link href="/about-us"><Button w="full" variant="solid" colorScheme="green">About Us</Button></Link> */}
                                        <Link href="https://www.upakenya.com"><Button variant="solid" colorScheme="green">Go back to upakenya.com</Button></Link>     
                                            <Link href="/login"><Button variant="solid" colorScheme="green">Login</Button></Link>
                                        </Stack>

                                    </DrawerBody>

                                    <DrawerFooter>
                                        <Button variant="outline" mr={3} onClick={onClose}>
                                            Cancel
                                        </Button>
                                        {/* <Button colorScheme="blue">Save</Button> */}
                                    </DrawerFooter>
                                </DrawerContent>
                            </Drawer>
                        </>
                    }

                </Box>
            </Stack>
            <Divider />
        </>
    )


}