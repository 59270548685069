// import { Stack, Box, Input, Button, Center, Container, Heading, 
//     useToast
//  } from '@chakra-ui/react'
// import {useState} from 'react'
// import { apiHost } from '../lib/api'
import NewsItems from './../components/News' 



export default function News() {

    return (
        <>
           <NewsItems/>
        </>
    )

}