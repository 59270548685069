import { Stack, Divider, Text,Box, Image, Heading, Link } from '@chakra-ui/react'
// import {CgProfile,} from 'react-icons/cg'
import news from './../data/news.json'

export default function Footer() {



    return (
        <>
            <Stack direction="column" backgroundColor="green" color="white">
                {news && news.map((newsItem) => {
                    return <NewsItem newsItem={newsItem} />
                })}
            
            </Stack>
            <Divider />
        </>
    )


}


let NewsItem = ({newsItem}) => {

    return <>
    <Box> 
        <Image src={newsItem.image}/>
        <Heading>{newsItem.title}</Heading>
        <Text>{newsItem.description}</Text>
        <Link href={newsItem.url}>read more .... </Link>

    </Box>
    </>
}