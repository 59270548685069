import {
    Container, Stack, Text, Box, Center, Heading, Divider,
    Input, Button, Radio, RadioGroup, Select, useToast, Checkbox
} from '@chakra-ui/react'
import { useState } from 'react'


export default function Registration() {

    let [data, setData] = useState({domain:'http://localhost:3000/', member_category:"Member"})
    let toast = useToast()
    let register = async () => {
        try {
            let response = await (await fetch('/api/method/vote.utils.election_details.member_profile_info', {
                body: JSON.stringify(data),
                headers:{'Content-Type':"application/json"},
                method:'POST'
            })).json()
            // console.log(response)
            if(response.status === 'success'){
                toast({
                    title: "Successfully registered",
                    description: "You were successfully registered,",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
                await new Promise(r => setTimeout(r, 2000));
                window.location.href = '/login'
                return
            }
            else{
                toast({
                    title: "User not registered",
                    description: "We could not register user",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                })
                return
            }
        } catch (error) {
            toast({
                title: "User not registered",
                description: error,
                status: "error",
                duration: 3000,
                isClosable: true,
            })
            return
        }
        
    }


    return (
        <>
            <Container minW="full" backgroundColor="red" color="white">
                <br />
                <Heading color="black">Member Registration</Heading>
                <br /><br />
                <Center>
                    <Box maxWidth="3xl">
                        <Text fontSize="x-large">
                            The party is founded on the means through which
                            the people express themselves and are given a voice
                            to run the government they want.
                        </Text>
                    </Box>
                </Center>
                <br /><br />
                <Divider minW="100%" />
            </Container>
            <Stack spacing="10" direction="column">

                <Box minH="">

                </Box>
                <Box minH="">

                </Box>
                <Box minH="">

                </Box>

            </Stack>
            <Container maxW={['sm', 'sm', 'lg', 'lg']} p="2">
                <Center>
                    <Text fontWeight="bold" fontSize="large">
                        Fill in the form to Apply for Party Membership
                    </Text>
                </Center>
                <Stack direction="column" borderRadius="lg" border="1px black solid" p='2'>
                <Stack direction="row"  p="2" >
                    <Input placeholder="Surname"
                        onChange={e => { setData({ ...data, surname: e.target.value }) }} />
                    <Input placeholder="Other names"
                        onChange={e => { setData({ ...data, other_names: e.target.value }) }} />
                </Stack>
                <Stack direction="row" p="2" >
                    <Input placeholder="Email Address"
                        onChange={e => { setData({ ...data, email: e.target.value }) }} />
                    <Input placeholder="Confirm email address"
                        onChange={e => { setData({ ...data, confirm_email: e.target.value }) }}
                    />
                </Stack>
                <Stack direction="row" p="2">
                    <Input placeholder="Phone"
                        onChange={e => { setData({ ...data, phone: e.target.value }) }} />
                    <Input placeholder="ID or Passport Number"
                        onChange={e => { setData({ ...data, id_number: e.target.value }) }}
                    />
                </Stack>
                <Stack direction="row" p="2">
                    <Text p="2">D.O.B</Text>
                    <Input placeholder="Date of birth" type="date"
                        onChange={e => { setData({ ...data, date_of_birth: e.target.value }) }}
                    />
                </Stack>
                <Stack direction="row" p="2">
                    <RadioGroup onChange={e => { setData({ ...data, gender: e }) }} >
                        <Stack direction="row">
                            <Radio value="Male">Male</Radio>
                            <Radio value="Female">Female</Radio>
                        </Stack>
                    </RadioGroup>
                </Stack>
                <Stack direction="row" p="2">
                    <RadioGroup onChange={e => { setData({ ...data, special_category: e }) }} >
                        <Stack direction="row">
                            <Radio value="PWD">PWD</Radio>
                            <Radio value="Youth">Youth</Radio>
                            <Radio value="Marginalized Communities">Marginalized Communities</Radio>
                            <Radio value="N/A">N/A</Radio>
                        </Stack>
                    </RadioGroup>
                </Stack>
                <Stack p="2">
                    <Select placeholder="Place of issue" 
                        onChange={e => { setData({ ...data, electoral_district: e.target.value }) }}>
                            <option value="Baringo">Baringo</option>
                            <option value="Bomet">Bomet</option>
                            <option value="bungoma">Bungoma</option>
                            <option value="busia">Busia</option>
                            <option value="elgeyo marakwet">Elgeyo Marakwet</option>
                            <option value="embu">Embu</option>
                            <option value="garissa">Garissa</option>
                            <option value="homa bay">Homa Bay</option>
                            <option value="isiolo">Isiolo</option>
                            <option value="kajiado">Kajiado</option>
                            <option value="kakamega">Kakamega</option>
                            <option value="kericho">Kericho</option>
                            <option value="kiambu">Kiambu</option>
                            <option value="kilifi">Kilifi</option>
                            <option value="kirinyaga">Kirinyaga</option>
                            <option value="kisii">Kisii</option>
                            <option value="kisumu">Kisumu</option>
                            <option value="kitui">Kitui</option>
                            <option value="kwale">Kwale</option>
                            <option value="laikipia">Laikipia</option>
                            <option value="lamu">Lamu</option>
                            <option value="machakos">Machakos</option>
                            <option value="makueni">Makueni</option>
                            <option value="mandera">Mandera</option>
                            <option value="meru">Meru</option>
                            <option value="migori">Migori</option>
                            <option value="marsabit">Marsabit</option>
                            <option value="mombasa">Mombasa</option>
                            <option value="muranga">Muranga</option>
                            <option value="nairobi">Nairobi</option>
                            <option value="nakuru">Nakuru</option>
                            <option value="nandi">Nandi</option>
                            <option value="narok">Narok</option>
                            <option value="nyamira">Nyamira</option>
                            <option value="nyandarua">Nyandarua</option>
                            <option value="nyeri">Nyeri</option>
                            <option value="samburu">Samburu</option>
                            <option value="siaya">Siaya</option>
                            <option value="taita taveta">Taita Taveta</option>
                            <option value="tana river">Tana River</option>
                            <option value="tharaka nithi">Tharaka Nithi</option>
                            <option value="trans nzoia">Trans Nzoia</option>
                            <option value="turkana">Turkana</option>
                            <option value="uasin gishu">Uasin Gishu</option>
                            <option value="vihiga">Vihiga</option>
                            <option value="wajir">Wajir</option>
                            <option value="pokot">West Pokot</option>
                    </Select>
                </Stack>
                <Stack direction="row" p="2">
                    <Checkbox onChange={e => {setData({...data, member_category: e.target.checked ? "Member and Volunteer" : "Member"})}}>Register as party volunteer</Checkbox>
                </Stack>
                <Stack direction="row" p="2">
                    <Text>Other Party Resignation Document</Text>
                    <Input placeholder="Resign from your Current Party by uploading the Resignation template document provided"
                        type="file"
                    />
                </Stack>
                <Stack direction="row" p="2">
                    <Button variant="solid"
                        w="full"
                        backgroundColor="darkgreen"
                        color="white"
                        onClick={register}
                    >Register</Button>
                </Stack>
                </Stack>
            </Container>
            <br/><br/><br/><br/>
        </>
    )


}