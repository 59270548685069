import {
    Container, Stack, Text, Box, Center, Heading, Divider, Image
} from '@chakra-ui/react'
import { useState } from 'react'

export default function Members() {

    // let containerWidths = ['container.sm', 'container.sm', 'container.xl', 'container.xl']
    let [members,] = useState([
        { name: "Hon. Tom Eliki", image: "images/party-chairman.jpeg", position: "Party Chairman" },
        { name: "Hon. Herbert Mwachiro", image: "images/deputy-party-leader.jpeg", position: "Deputy Party Leader" },
        { name: "Hon. John Obwocha", image: "images/secretary-general.jpeg", position: "Secretary General" },
        { name: "Hon. James Gichana", image: "images/treasurer.jpeg", position: "Treasurer" },
    ])

    // let setValue = () => { }


    return (
        <>
            <Container maxW="full"
                backgroundColor="red" color="white">
                <br />
                <Heading color="black">Our Team</Heading>
                <br />
                <Divider minW="100%" />
            </Container>
            <br />  <br />

            <Container maxW="full">
                <Center>
                    <Stack direction="row" spacing="4">
                        {members.slice(0, 2).map((member) => {
                            return <MemberCard name={member.name} description={member.position} image={member.image} />
                        })}
                    </Stack>
                </Center>
                <br /> <br />
                <Center>
                    <Stack direction="row" spacing="4">
                        {members.slice(2, 4).map((member) => {
                            return <MemberCard name={member.name} description={member.position} image={member.image} />
                        })}
                    </Stack>
                </Center>
            </Container>
            <br />
            <br />
            <br />
            <br />
            <br />


        </>
    )


}


let MemberCard = ({ name, description, image }) => {

    return <>
        <Box>
            <Image src={image} height="64" w={['2xs', '2xs', '3md', '2md']} borderRadius="lg" />
            <br />
            <Heading fontSize="large">{name}</Heading>
            <Text backgroundColor="green" borderRadius="md">{description}</Text>
        </Box>
    </>

}