import { Stack, Divider, Text } from '@chakra-ui/react'
// import {CgProfile} from 'react-icons/cg'

export default function Footer() {

    return (
        <>
            <Stack direction="column" backgroundColor="black" color="white">
                <Stack p="8" direction="column" textAlign="center">
                    <Text >© 2021 All Rights Reserved. UPA</Text>
                </Stack>
            </Stack>
            <Divider />
        </>
    )


}