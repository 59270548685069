import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

export async function generatePDF(text) {
    const url = '/certificate_template.pdf'
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
  
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold)
  
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const { width, height } = firstPage.getSize()
    firstPage.drawText(text, {
      x: width /(3.5) ,
      y: height /(2.1) ,
      size: 30,
      font: helveticaFont,
      color: rgb(0,0.6,0),
    //   rotate: degrees(-45),
    })
  
    const pdfBytes = await pdfDoc.save()
    var blob = new Blob([pdfBytes], {type: "application/pdf"});
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "certificate_of_membership.pdf";
    link.click()
  }