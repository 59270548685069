import { Box, Container, Text, Heading, Divider, Alert, AlertDescription, Button} from '@chakra-ui/react'
import {useState, useEffect} from 'react'
import { generatePDF } from '../lib/generatePDF'


export default function Profile() {


    let [data, setData] = useState(null)

    useEffect(() => {
        console.log(data)
        if(!(JSON.parse(window.localStorage.getItem('profileInfo')))){
            window.location.href = '/login'
            return
        }
        setData(JSON.parse(window.localStorage.getItem('profileInfo')))
        window.localStorage.removeItem('profileInfo')
    }, [])
    

    return (
        <>
            <Container maxW="full">
                <br/>
                {data && 
                <Alert status="warning">
                    <AlertDescription>
                        Reloading this page will log you out.
                    </AlertDescription>
                </Alert>
                }
                {data && <Box p="4" backgroundColor="green" borderRadius="lg">
                    {/* <Text>{JSON.stringify(data)}</Text> */}
                    <br/>
                    <Heading textColor="white">Membership Info.</Heading>
                    <Divider/>
                    <br/>
                    <Heading color="white">{"Name: " +  data.full_name}</Heading>
                    <Text fontSize="large">{"Electoral district: " +  data.electoral_district}</Text>
                    <Text fontSize="large">{"ID Number: " +  data.id_number}</Text>
                    <Text fontSize="large">{"Date of Birth: " +  data.date_of_birth}</Text>
                    <Text fontSize="large">{"Gender: " +  data.gender}</Text>
                    <Text fontSize="large">{"Member Category: " +  data.member_category}</Text>
                    <Text fontSize="large">{"Special Category: " +  data.special_category}</Text>

                    <br/>
                    <Text fontSize="small">Reload page to Sign Out</Text>

                    <Button colorScheme="green" variant="solid" onClick={e => {generatePDF(data.full_name)}}>
                        Download Cerificate of Membership</Button>
                </Box>}

                <br/><br/><br/><br/><br/><br/><br/><br/>
            </Container>
        </>
    )


}