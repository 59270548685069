import {
    Container, Stack, Text, Center, Heading, Divider, Icon, Spacer, Link
} from '@chakra-ui/react'
import { HiCloudDownload } from 'react-icons/hi'
// import { generatePDF } from '../lib/generatePDF'

export default function Downloads() {

    // let containerWidths = ['container.sm', 'container.sm', 'container.xl', 'container.xl']

    // let setValue = () => { }

    let files = [
        {name:"UPA Constitution", link:"/file.pdf"}
    ]


    return (
        <>
            <Container maxW="full"
                backgroundColor="red" color="white">
                <br />
                <Heading color="black">Downloads</Heading>
                <Center>
                    {/* <Box maxWidth="3xl">
                        <Text fontSize="x-large">
                            The party is founded on the means through which
                            the people express themselves and are given a voice
                            to run the government they want.
                        </Text>
                    </Box> */}
                </Center>
                <br /><br />
                <Divider minW="100%" />
            </Container>
            <br/>  <br/> 

            {files.map((file) => {
                return <Download file={file.link} name={file.name} />
            })}

            {/* <Center>
                <Button colorScheme="green" onClick={e=>{ generatePDF("LOREM IPSUM MARK")}}>Download Party Membership Certificate</Button>
            </Center> */}

            

           
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
s
            
        </>
    )


}


let Download = ({name, link}) => {

    return (
        <>
        <Center>
        <Stack direction="row" width={['sm', 'sm', 'lg', 'lg']} h="20" borderRadius="lg" border="1px black solid" p="4">
            <Heading fontSize="xl">{name}</Heading>
            <Spacer/>

            <Link href={link}>
            <Text>Click to download</Text>
            <Icon as={HiCloudDownload} color="green" fontSize="3xl"></Icon>
            </Link>

        </Stack>
        </Center>
        <br/>
        </>
    )
}
