import Header from './components/Header'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs'
import Login from './pages/Login'
import Members from './pages/Members'
import Registration from './pages/Registration'
import News from './pages/News'
import Profile from './pages/Profile'
import Footer from './components/Footer'
import Downloads from './pages/Downloads'
import Gallery from './pages/Gallery'
import Volunteer from './pages/Volunteer'

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/">
          <Registration />
        </Route>
        <Route exact path="/register">
          <Registration />
        </Route>
        <Route exact path="/about-us">
          <AboutUs />
        </Route>
        <Route exact path="/our-team">
          <Members />
        </Route>
        <Route exact path="/contact-us">
          <ContactUs />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/news">
          <News />
        </Route>
        <Route exact path="/profile">
          <Profile />
        </Route>
        <Route exact path="/downloads">
          <Downloads />
        </Route>
        <Route exact path="/gallery">
          <Gallery />
        </Route>
        <Route exact path="/vounteer">
          <Volunteer />
        </Route>
      </Switch>
      <Footer/>
    </Router>
  );
}

export default App;
