import {
    Container, Stack, Text, Box, Center, Heading, Divider,
    Input, Button, Radio, RadioGroup, Select, useToast,
    useDisclosure, Modal, ModalBody, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalFooter, Link
} from '@chakra-ui/react'
import { useState } from 'react'
import countyToConstituency from './../data/county_to_consituencies.json'
import counties from './../data/counties.json'
import consituencyToWard from './../data/consituencies_to_ward.json'


export default function Registration() {

    let [data, setData] = useState({ domain: 'https://www.upakenya.com', member_category: "Member" })
    let [selectedCounty, setCounty] = useState('')
    let [selectedConsituency, setConstituency] = useState('')
    let [verified, setVerified] = useState(false)
    let { isOpen, onOpen, onClose } = useDisclosure()
    let [otp, setOtp] = useState(null)
    let toast = useToast()
    let toastError = ({title, description}) => {
        toast({
            title: title,
            description,
            status: "error",
            duration: 5000,
            isClosable: true,
        })
    }

    let register = async () => {
        if(!validate()){return}
        // if (!verified) {
        //     await sendOTP(data.phone)
        //     onOpen() // pop verification modal 
        //     return
        // }
        let res = await verifyAndRegister()
        console.log(res)
        return res
    }

    let sendOTP = async () => {
        let response = await (await fetch('/api/method/vote.utils.election_details.member_login', {
            body:JSON.stringify({ domain: 'https://www.upakenya.com', phone: data.phone, id_number: data.id_number  }),
            headers: {'Content-Type':'application/json'},
            method: 'POST'
        })).json()
        console.log(response)

        toast({
            title: "OTP sent",
            description: `Your OTP was sent to ${data.phone}`,
            status: "success",
            duration: 5000,
            isClosable: true,
        })
        return true
    }

    let authenticateOTP = async () => {
        let response = await (await fetch('/api/method/vote.utils.election_details.member_login', {
            body:JSON.stringify({ domain: 'https://www.upakenya.com', 
                phone: data.phone, id_number: data.id_number, otp: otp  }),
            headers: { 'Content-Type': 'application/json' },
            method: 'POST'
        })).json()
        if(response.status === 'success'){
            if('member_info'in response){
                setVerified(true)
                return true
            }
        }
        return false
    }
    let requiredFields = [
        'member_category', 'surname', 'other_names', 'email', 'confirm_email', 'phone', 'id_number',
        'date_of_birth', 'gender', 'special_category', 'county', 'constituency', 'electoral_district', 'ward'
    ]

    let validate = () => {
        for(let i of requiredFields){
            if(!(i in data)){
                console.log(i)
                toastError({title:"Missing required field(s)", description:`The field *${i}* is required.`})
                return false
            }
        }
        if(((new Date() - (new Date(data.date_of_birth))) / 31557600000) < parseFloat(18) ){
            toastError({title:"Age Limit.", description:`You are required to be above 18 years.`})
            return false
        }
        return true
    } 
    let verifyAndRegister = async () => {
        if(!validate()){return}
        try {
            try {
                let response = await (await fetch('/api/method/vote.utils.election_details.member_profile_info', {
                    body: JSON.stringify(data),
                    headers: { 'Content-Type': "application/json" },
                    method: 'POST'
                })).json()
                // console.log(JSON.stringify(data))
                // await new Promise(r => setTimeout(r, 7000));
                if (response.status === 'success') {
                    toast({
                        title: "Successfully registered",
                        description: "You were successfully registered,",
                        status: "success",
                        duration: 3000,
                        isClosable: true,
                    })
                    let sendConfirmation = await (await fetch('/api/method/vote.utils.election_details.send_registration_confirmation', {
                        body: JSON.stringify({domain: 'https://www.upakenya.com', phone: data.phone, id_number: data.id_number}),
                        headers: { 'Content-Type': "application/json" },
                        method: 'POST'
                    })).json()
                    console.log(sendConfirmation)
                    await new Promise(r => setTimeout(r, 1000));
                    window.location.href = '/login'
                    return
                }
                else {
                    toast({
                        title: "User not registered",
                        description: "We could not register user",
                        status: "error",
                        duration: 3000,
                        isClosable: true,
                    })
                    return
                }
            } catch (error) {
                toast({
                    title: "User not registered",
                    description: error,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                })
                return
            }
        } catch (error) {
            toast({
                title: "OTP Verification Error",
                description: `You provided an invalid OTP Code.`,
                status: "error",
                duration: 3000,
                isClosable: true,
            })   
        }
    }


    return (
        <>
            <Container minW="full" backgroundColor="red" color="white">
                <br />
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Verify Mobile Number</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Input placeholder="OTP Code" onChange={e => {setOtp(e.target.value)}}/>
                        </ModalBody>

                        <ModalFooter>
                            {/* <Button colorScheme="red" mr={3} onClick={onClose}>
                                Close
                            </Button> */}
                            <Button variant="solid" colorScheme="green" disabled={otp? false: true}
                            onClick={e => {verifyAndRegister()}}>Complete Registration</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
                <Heading color="black">Member Registration</Heading>
                {/* <br /><br />
                <Center>
                    <Box maxWidth="3xl">
                        <Text fontSize="x-large">
                            The party is founded on the means through which
                            the people express themselves and are given a voice
                            to run the government they want.
                        </Text>
                    </Box>
                </Center> */}
                <br />
                <Divider minW="100%" />
            </Container>
            <Stack spacing="10" direction="row">
                <Box minH=""></Box>
                <Box minH=""> 

                </Box>
                <Box minH="">
                <br/>
                </Box>
            </Stack>
            <Container maxW={['sm', 'sm', 'lg', 'lg']} p="2">
                <Center>
            <Link href="https://www.upakenya.com"><Button variant="solid" colorScheme="green">Go back to upakenya.com</Button></Link>
                    </Center>
                    <br/>
                <Center>
                    <Text fontWeight="bold" fontSize="large">
                        Fill in the form to Apply for Party Membership
                    </Text>
                </Center>
                <Stack direction="column" borderRadius="lg" border="1px black solid" p='2'>
                    <Stack direction="row" p="2" >
                        <Input placeholder="Surname"
                            onChange={e => { setData({ ...data, surname: e.target.value }) }} />
                        <Input placeholder="Other names"
                            onChange={e => { setData({ ...data, other_names: e.target.value }) }} />
                    </Stack>
                    <Stack direction="row" p="2" >
                        <Input placeholder="Email Address"
                            onChange={e => { setData({ ...data, email: e.target.value }) }} />
                        <Input placeholder="Confirm email address"
                            onChange={e => { setData({ ...data, confirm_email: e.target.value }) }}
                        />
                    </Stack>
                    <Stack direction="row" p="2">
                        <Input placeholder="Phone number"
                            onChange={e => { setData({ ...data, phone: e.target.value }) }} />
                        <Input placeholder="ID or Passport Number"
                            onChange={e => { setData({ ...data, id_number: e.target.value }) }}
                        />
                    </Stack>
                    <Stack direction="row" p="2">
                        <Text p="2">D.O.B</Text>
                        <Input placeholder="Date of birth" type="date"
                            onChange={e => { setData({ ...data, date_of_birth: e.target.value }) }}
                        />
                    </Stack>
                    <Stack direction="row" p="2">
                        <RadioGroup onChange={e => { setData({ ...data, gender: e }) }} >
                            <Stack direction="row">
                                <Radio value="Male">Male</Radio>
                                <Radio value="Female">Female</Radio>
                            </Stack>
                        </RadioGroup>
                    </Stack>
                    <Stack direction="row" p="2">
                        <RadioGroup onChange={e => { setData({ ...data, special_category: e }) }} >
                            <Stack direction="row">
                                <Radio value="PWD">PWD</Radio>
                                <Radio value="Youth">Youth</Radio>
                                <Radio value="Marginalized Communities">Marginalized Communities</Radio>
                                <Radio value="N/A">N/A</Radio>
                            </Stack>
                        </RadioGroup>
                    </Stack>
                    <Stack p="2">
                        <Select placeholder="Select County"
                            onChange={e => { setCounty(e.target.value); setData({ ...data, county: counties[parseInt(e.target.value)].name, }) }}>
                            {counties && counties.map((county) => {
                                return <option value={county.code}>{county.name}</option>
                            })}

                        </Select>
                    </Stack>
                    {selectedCounty &&
                        <Stack p="2">
                            <Select placeholder="Select Consituency"
                                onChange={e => { setConstituency(e.target.value); setData({ ...data, constituency: e.target.value }) }}>
                                {countyToConstituency && countyToConstituency[selectedCounty].map((consituency) => {
                                    return <option value={consituency.code}>{consituency.name}</option>
                                })}

                            </Select>
                        </Stack>
                    }
                    {selectedConsituency &&
                        <Stack p="2">
                        <Select placeholder="Select Ward"
                            onChange={e => { setData({ ...data, electoral_district: e.target.value, ward: e.target.value }) }}>
                            {consituencyToWard && consituencyToWard[selectedConsituency].map((ward) => {
                                return <option value={`${ward.name} WARD`.toUpperCase()}>{ward.name}</option>
                            })}

                        </Select>
                    </Stack>
                        
                    }
                    
                        <Stack p="2">
                            <Select placeholder="Member Type"
                                onChange={e => { setData({ ...data, member_category: e.target.value }) }}>
                                <option value="Member">Member Only</option>
                                <option value="Member and Volunteer">Member and Volunteer</option>
                                <option value="Member and Aspirant">Member and Aspirant</option>
                            </Select>
                        </Stack>
                    <Stack direction="row" p="2">
                        <Text>Other Party Resignation Document</Text>
                        <Input placeholder="Resign from your Current Party by uploading the Resignation template document provided"
                            type="file"
                        />
                    </Stack>
                    <Stack direction="row" p="2">
                        <Button variant="solid"
                            w="full"
                            backgroundColor="darkgreen"
                            color="white"
                            onClick={register}
                        >Register</Button>
                    </Stack>
                </Stack>
            </Container>
            <br /><br />
        </>
    )
}