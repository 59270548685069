import { Stack, Input, Button, Center, Container, Heading, 
    useToast
 } from '@chakra-ui/react'
import {useState, useEffect} from 'react'


export default function Login() {

    let [otp, setOtp] = useState(null)
    let toast = useToast()
    let [resendOTP, allowResendOTP] = useState(false)
    let [data, setData] = useState({domain: "https://www.upakenya.com"})
    let [timer, setTimer] = useState(25)


    let requestOTP = async () => {
        let response = await (await fetch('/api/method/vote.utils.election_details.member_login', {
            body:JSON.stringify(data),
            headers: {'Content-Type':'application/json'},
            method:'POST'
        })).json()
        // let response = {status:"success"}

        console.log(response)
        if(response.status === 'success'){
            toast({
                title: "OTP sent",
                description: `Your OTP was sent to ${data.phone}`,
                status: "success",
                duration: 4000,
                isClosable: true,
              })
            setOtp(true)
            allowResendOTP(true)
            return
        }
        toast({
            title: "OTP Error",
            description: `Could not send OTP to ${data.phone}. Try again later.`,
            status: "error",
            duration: 4000,
            isClosable: true,
          })
        return
    }
    
    let login = async () => {
        if(!data.otp || (data.otp.length !== 8)){
            toast({
                title: "Valid OTP is required",
                description: `You provided an Invalid OTP`,
                status: "error",
                duration: 4000,
                isClosable: true,
              })
            return
        }
        let response = await (await fetch('/api/method/vote.utils.election_details.member_login', {
            body:JSON.stringify(data),
            headers: {'Content-Type':'application/json'},
            method: 'POST'
        })).json()
        // console.log("login", response)
        
        if(response.status === 'success' && ('member_info' in response) ){
            console.log(response)
            window.localStorage.setItem('profileInfo', JSON.stringify(response.member_info[0]))
            window.location.href = "/profile"
            return
        }

        if (response.status === 'error'){
            toast({
                title: "Invalid OTP",
                description: response.message,
                status: "error",
                duration: 6000,
                isClosable: true,
            })
            return
        }else{
            toast({
                title: "Invalid OTP provided",
                description: "Check the OTP or phone number and try again",
                status: "error",
                duration: 4000,
                isClosable: true,
            })
            return
        }
       
        
    }

    useEffect(() => {
        let runTimer = setTimeout(() => {
            setTimer(timer - 1)
            // console.log(timer)
            // if(timer === -10){
            //     allowResendOTP(false)
            // }
        }, 1000)
    }, [timer])

    

    return (
        <>
            <Container w="full">
                <br/><br/><br/><br/>
                    <Stack direction="column">
                    <Center>
                <Heading fontSize="large" fontWeight="semibold">Provide the following details to login</Heading>
                    </Center>        
                    <br/>
                    <Stack direction="row" p="2">
                        <Input type="number" placeholder="Phone Number" onChange={
                            e => {setData({...data, phone: e.target.value})}
                        } />
                    </Stack>
                    <Stack direction="row" p="2">
                        <Input type="number" placeholder="ID Number" 
                        onChange={e => {setData({...data, id_number: e.target.value})}}
                        />
                    </Stack>
                    { otp && 
                    <Stack direction="row" p="2">
                        <Input type="text" placeholder="One-time Password" 
                        onChange={e => {setData({...data, otp: e.target.value})}}
                        />
                    </Stack>
                    }
                    

                    <Stack direction="row" p="2">
                    {otp ?
                    <Button variant="solid" w="32" backgroundColor="darkgreen" disabled={(data.otp) ? false: true }
                        onClick={login} color='white'
                    >Login</Button>  :
                    <Button variant="solid" w="32" backgroundColor="darkgreen"
                        onClick={requestOTP} color='white'
                    >Request OTP</Button>
                    }
                    { resendOTP && <Button variant="solid" w="32" backgroundColor="darkgreen" disabled={timer > 0 ? true : false}
                        onClick={requestOTP} color='white'>Resend OTP {(timer > 0) ? `(${timer})` : ''}</Button>
                    }
                    </Stack>
                    </Stack>
           
            <br/><br/><br/><br/><br/><br/><br/><br/>
            </Container>
        </>
    )


}