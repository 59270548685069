import { Stack, Container, Center, Box, Text, Heading, Divider, Image, useMediaQuery } from '@chakra-ui/react'

export default function AboutUs(){


    let [isMobile] = useMediaQuery("(max-width:600px)");
    return(
        <>
        <Container minW="full"
                backgroundColor="red" color="white">
                <br />
                <Heading color="black">About Us</Heading>
                {/* <br /><br />
                <Center>
                    
                </Center> */}
                <br /><br />
                <Divider minW="100%" />
            </Container>
            <Container
            maxW="full">
                <br/>
                <Stack direction={isMobile? "column":"row"} spacing="2">
                    {/* text  */}
                    <Box p="8" backgroundColor="green" borderRadius="xl">
                        <Text fontSize="large" fontWeight="bold">About Party</Text>
                        <Heading color="white">
                            Let's make the country better together
                        </Heading>
                        <br/>
                        <Text fontWeight="semibold" fontSize="large">
                            Our party is is founded on the principles of National unity, 
                            Peace and an indivisible country that prides in integrity, 
                            economic empowerment and safeguarding the wellbeing of the people of Kenya.
                        </Text>
                        <br/>
                        <Text color="white">
                        The party is founded on the means through which the people express themselves and are given a voice to run the government they want. 
                        We are built on the following tenets of social economic progressivism ideology:-
                        </Text>
                    </Box>
                    {/* image */}
                    {!isMobile && <Box p="2" maxW="full">
                        <Image
                        minWidth="lg"
                        src="https://t4.ftcdn.net/jpg/02/06/64/59/360_F_206645985_LUU5rXVvAUz93icXzylypF2RJJ4y9RxL.jpg" />

                    </Box>}

                </Stack>
            </Container>
            <br/>
            <Divider></Divider>
            <br/><br/>
            <Container maxW="full"
            >
                <Stack direction="row" p="4">

                    <Box>
                        <Text>Vote Our Party</Text>
                        <Heading>
                        Help us bring the change we’re looking for
                        </Heading>
                        <Text>
                        Our party is founded on the principles of National unity,<br/> Peace and an indivisible country that prides in integrity, 
                        economic empowerment and safeguarding <br/> the wellbeing of the people of Kenya.
                        </Text>
                    </Box>
                    <Box>
                        <Heading>
                            {/* Watch the video */}
                        </Heading>
                    </Box>
                    
                </Stack>
            <br/><br/><br/>
                
            </Container>
            <Divider></Divider>

            <Container minW="full" backgroundColor="black" color="white">
                <br/><br/>
            <Heading color="white">Party Statistics</Heading>
            <br/>
            <Center>
            <Stack direction={isMobile? "column":"row"} spacing="24">
                <Box padding="4" borderRadius="lg" backgroundColor="green" w="2xs">
                    <Heading>40</Heading>
                    <Text>Party Workers</Text>
                </Box>
                <Box p="4" borderRadius="lg" backgroundColor="green" w="2xs">
                    <Heading>40</Heading>
                    <Text>New Members</Text>
                </Box>
                <Box p="4" borderRadius="lg" backgroundColor="green" w="2xs">
                    <Heading>100k</Heading>
                    <Text>Donations</Text>
                </Box>
                <Box p="4" borderRadius="lg" backgroundColor="green" w="2xs">
                    <Heading>40</Heading>
                    <Text>Days to Elections</Text>
                </Box>
            </Stack>
            </Center>
            <br/><br/>
            </Container>
            <br/><br/>
        </>
    )
}